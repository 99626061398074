import { useContext, useEffect, useState, useRef } from "react";
import { message } from "antd";
import {
  GanttComponent,
  DayMarkers,
  EditDialogFieldsDirective,
  EditDialogFieldDirective,
  AddDialogFieldsDirective,
  AddDialogFieldDirective,
  Inject,
  Edit,
  Resize,
  Selection,
  Toolbar,
  ContextMenu,
  ColumnsDirective,
  ColumnDirective,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-gantt";
import type { TimelineSettingsModel } from "@syncfusion/ej2-react-gantt";
import type { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { GlobalContext } from "../../../lib/context";
import request from "../../../utils/request";
import dayjs from "dayjs";

const MainTab = () => {
  const ganttRef = useRef<GanttComponent>(null);
  const { currentProject } = useContext(GlobalContext);
  const [tasks, setTasks] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [visibleAllocation, setVisibleAllocation] = useState<boolean>(true);

  const processData = (tasks: any[]) => {
    const taskMap: any = {};

    tasks.forEach((task: any) => {
      task.subtasks = [];

      // Set ScheduleDate, ActualDate
      task.ActualStartDate = task.ActualStartDate
        ? new Date(task.ActualStartDate)
        : null;
      task.ActualEndDate = task.ActualEndDate
        ? new Date(task.ActualEndDate)
        : null;
      task.StartDate = task.StartDate ? new Date(task.StartDate) : null;
      task.EndDate = task.EndDate ? new Date(task.EndDate) : null;

      taskMap[task.TaskID] = task;
    });

    const out: any[] = [];

    tasks.forEach((task) => {
      if (task.ParentTaskId === 0) {
        out.push(task);
      } else {
        const parentTask = taskMap[task.ParentTaskId];
        if (parentTask) {
          parentTask.subtasks.push(task);
        }
      }
    });

    return out;
  };

  const fetchTasks = async () => {
    try {
      setLoading(true);
      const _tasks: any = await request.post("tasks/get", {
        id: currentProject["project_id"],
      });
      setTasks(processData(_tasks));
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [
    currentProject["project_id"],
    currentProject["schedule_start_date"],
    currentProject["schedule_end_date"],
  ]);

  const taskFields = {
    id: "TaskID",
    name: "TaskName",
    startDate: "StartDate",
    endDate: "EndDate",
    duration: "Duration",
    progress: "Progress",
    dependency: "Predecessor",
    child: "subtasks",
    actualStartDate: "ActualStartDate",
    actualEndDate: "ActualEndDate",
  };
  const editSettings = {
    allowAdding: currentProject["project_status"] === "Open",
    allowEditing: currentProject["project_status"] === "Open",
    allowDeleting: currentProject["project_status"] === "Open",
    allowTaskbarEditing: currentProject["project_status"] === "Open",
    showDeleteConfirmDialog: currentProject["project_status"] === "Open",
  };
  const toolbar = [
    "Add",
    "Edit",
    "Update",
    "Delete",
    "Cancel",
    "ExpandAll",
    "CollapseAll",
    {
      text: "Show/Hide Overallocation",
      tooltipText: "Show/Hide Overallocation",
      id: "showhidebar",
    },
    "ZoomIn",
    "ZoomOut",
    "ZoomToFit",
    "ExcelExport",
    "CsvExport",
    "PdfExport",
  ];
  const timelineSettings: TimelineSettingsModel = {
    topTier: {
      unit: "Week",
      format: "y-MM-dd",
    },
    bottomTier: { unit: "Day" },
  };

  const contextMenuItems: any = [
    "TaskInformation",
    "DeleteTask",
    "Save",
    "Cancel",
    "SortAscending",
    "SortDescending",
    "Add",
    "DeleteDependency",
    "Indent",
    "Outdent",
  ];

  const progressTemplate = (props: any) => {
    return props.taskData.Progress + "%";
  };

  const onClickToolbar = (args: ClickEventArgs) => {
    if (args.item.id === "showhidebar") {
      setVisibleAllocation((prev) => !prev);
    } else if (args.item.id?.endsWith("_excelexport")) {
      if (ganttRef.current) {
        ganttRef.current.excelExport();
      }
    } else if (args.item.id?.endsWith("_csvexport")) {
      if (ganttRef.current) {
        ganttRef.current!.csvExport();
      }
    } else if (args.item.id?.endsWith("_pdfexport")) {
      if (ganttRef.current) {
        ganttRef.current!.pdfExport({
          fitToWidthSettings: { isFitToWidth: true },
        });
      }
    }
  };

  const onAction = async (args: any) => {
    try {
      switch (args.requestType) {
        case "add": {
          const _tasks: any = await request.post("tasks/add", {
            id: currentProject["project_id"],
            data: {
              ...args.newTaskData,
              ParentTaskId: args.data?.parentItem?.taskId || 0,
            },
          });
          setTasks(processData(_tasks));
          break;
        }
        case "save": {
          const _tasks: any = await request.post("tasks/update", {
            id: currentProject["project_id"],
            data: args.modifiedTaskData,
          });
          setTasks(processData(_tasks));
          break;
        }
        case "delete": {
          const _tasks: any = await request.post("tasks/update", {
            id: currentProject["project_id"],
            data: args.modifiedTaskData,
            target: args.data[0]?.TaskID || null,
          });
          setTasks(processData(_tasks));
          ganttRef.current?.refresh();
          break;
        }
      }
    } catch (error: any) {
      message.error(error.message);
      await fetchTasks();
    }
  };

  if (loading) {
    return (
      <div className="d-grid-center bg-white p-4 border h-full">Loading...</div>
    );
  }

  if (
    !dayjs(currentProject["schedule_start_date"], "YYYY-MM-DD").isValid() ||
    !dayjs(currentProject["schedule_end_date"], "YYYY-MM-DD").isValid()
  ) {
    return (
      <div className="bg-white p-4 border h-full">
        Please select&nbsp;
        <span className="text-info heading">Schedule Start Date</span> and&nbsp;
        <span className="text-info heading">Schedule End Date</span>
      </div>
    );
  }

  return (
    <GanttComponent
      ref={ganttRef}
      includeWeekend={true}
      highlightWeekends={true}
      dataSource={tasks}
      dateFormat="y-MM-dd"
      treeColumnIndex={1}
      allowSelection={true}
      showColumnMenu={false}
      allowUnscheduledTasks={true}
      projectStartDate={dayjs(
        currentProject["schedule_start_date"],
        "YYYY-MM-DD"
      )
        .subtract(7, "day")
        .format("YYYY-MM-DD")}
      projectEndDate={dayjs(currentProject["schedule_end_date"], "YYYY-MM-DD")
        .add(1, "day")
        .format("YYYY-MM-DD")}
      taskFields={taskFields}
      timelineSettings={timelineSettings}
      splitterSettings={{ position: "50%" }}
      height="100%"
      editSettings={editSettings}
      gridLines="Both"
      toolbar={toolbar}
      labelSettings={{ leftLabel: "TaskName", taskLabel: "Progress" }}
      loadingIndicator={{ indicatorType: "Shimmer" }}
      enableContextMenu={true}
      allowResizing={true}
      contextMenuItems={contextMenuItems}
      toolbarClick={onClickToolbar}
      showOverAllocation={visibleAllocation}
      allowExcelExport={true}
      allowPdfExport={true}
      actionComplete={onAction}
    >
      <ColumnsDirective>
        <ColumnDirective
          field="TaskID"
          width="50"
          textAlign="Right"
        ></ColumnDirective>
        <ColumnDirective
          field="TaskName"
          headerText="Task Name"
          width="300"
          clipMode="EllipsisWithTooltip"
        ></ColumnDirective>
        <ColumnDirective field="StartDate" textAlign="Right"></ColumnDirective>
        <ColumnDirective field="EndDate" textAlign="Right"></ColumnDirective>
        <ColumnDirective field="Duration" textAlign="Right"></ColumnDirective>
        <ColumnDirective
          field="ActualStartDate"
          editType="datepickeredit"
          format={{ type: "date", format: "y-MM-dd" }}
          textAlign="Right"
        ></ColumnDirective>
        <ColumnDirective
          field="ActualEndDate"
          editType="datepickeredit"
          format={{ type: "date", format: "y-MM-dd" }}
          textAlign="Right"
        ></ColumnDirective>
        <ColumnDirective
          field="Progress"
          textAlign="Right"
          template={progressTemplate}
        ></ColumnDirective>
        <ColumnDirective field="Predecessor"></ColumnDirective>
      </ColumnsDirective>
      <AddDialogFieldsDirective>
        <AddDialogFieldDirective
          type="Custom"
          headerText="General"
          fields={[
            "TaskName",
            "Progress",
            "StartDate",
            "EndDate",
            "ActualStartDate",
            "ActualEndDate",
          ]}
        ></AddDialogFieldDirective>
        <AddDialogFieldDirective type="Dependency"></AddDialogFieldDirective>
      </AddDialogFieldsDirective>
      <EditDialogFieldsDirective>
        <EditDialogFieldDirective
          type="Custom"
          headerText="General"
          fields={[
            "TaskName",
            "Progress",
            "StartDate",
            "EndDate",
            "ActualStartDate",
            "ActualEndDate",
          ]}
        ></EditDialogFieldDirective>
        <EditDialogFieldDirective type="Dependency"></EditDialogFieldDirective>
      </EditDialogFieldsDirective>
      <Inject
        services={[
          Edit,
          Selection,
          Toolbar,
          Resize,
          DayMarkers,
          ContextMenu,
          ExcelExport,
          PdfExport,
        ]}
      />
    </GanttComponent>
  );
};

export { MainTab };
