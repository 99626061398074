import { Link } from "react-router-dom";
import styles from "./styles.module.css";

const Navbar = () => {
  return (
    <div className={styles["navbar"]}>
      <div className="d-flex items-center gap-2 py-2 container">
        <Link to="/navigation" className="nav-item">
          <img src="/icons/icon-home.svg" alt="" width="18" />
          Home
        </Link>
        <Link to="/collaboration" className="nav-item">
          <img src="/icons/icon-team_collaboration.svg" alt="" width="18" />
          Collaboration
        </Link>
        <Link to="/requirements" className="nav-item">
          <img src="/icons/icon-scope.svg" alt="" width="18" />
          Requirements
        </Link>
        <Link to="/assumptions" className="nav-item">
          <img src="/icons/icon-assumptions.svg" alt="" width="18" />
          Assumptions
        </Link>
        <Link to="/project_documents" className="nav-item">
          <img src="/icons/icon-documents.svg" alt="" width="18" />
          Project Documents
        </Link>
        {/* <Link to="/project_summary" className="nav-item">
        <img src="/icons/icon-project_summary.svg" alt="" width="18" />
        Project Summary
      </Link>
      <Link to="/project_contacts" className="nav-item">
        <img src="/icons/icon-project_contacts.svg" alt="" width="18" />
        Project Contacts
      </Link>
      <Link to="/share_project" className="nav-item">
        <img src="/icons/icon-share_project.svg" alt="" width="18" />
        Share Project
      </Link>
      <Link to="/stakeholders" className="nav-item">
        <img src="/icons/icon-stakeholders.svg" alt="" width="18" />
        Stakeholders
      </Link>
      <Link to="/risk_management" className="nav-item">
        <img src="/icons/icon-risks.svg" alt="" width="18" />
        Risks Management
      </Link>
      <Link to="/time_tracking" className="nav-item">
        <img src="/icons/icon-time_tracking.svg" alt="" width="18" />
        Time Tracking
      </Link>
      <Link to="/change_management" className="nav-item">
        <img src="/icons/icon-change.svg" alt="" width="18" />
        Change Management
      </Link>
      <Link to="/issues" className="nav-item">
        <img src="/icons/icon-issue.svg" alt="" width="18" />
        Issues
      </Link>
      <Link to="/ccb" className="nav-item">
        <img src="/icons/icon-ccb.svg" alt="" width="18" />
        Change Control Board
      </Link> */}
      </div>
    </div>
  );
};

export default Navbar;
