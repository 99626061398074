import { useContext, useEffect, useState, useRef } from "react";
import { message } from "antd";
import {
  GanttComponent,
  Inject,
  Selection,
  Toolbar,
  DayMarkers,
  ColumnsDirective,
  ColumnDirective,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-gantt";
import type { TimelineSettingsModel } from "@syncfusion/ej2-react-gantt";
import type { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { GlobalContext } from "../../../lib/context";
import request from "../../../utils/request";
import dayjs from "dayjs";

const BaselineTab = () => {
  const ganttRef = useRef<GanttComponent>(null);
  const { currentProject } = useContext(GlobalContext);
  const [tasks, setTasks] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchTasks = async () => {
    try {
      setLoading(true);
      const _tasks: any = await request.post("tasks/get", {
        id: currentProject["project_id"],
      });
      setTasks(_tasks);
    } catch (error: any) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [
    currentProject["project_id"],
    currentProject["schedule_start_date"],
    currentProject["schedule_end_date"],
  ]);

  const taskFields = {
    id: "TaskID",
    name: "TaskName",
    startDate: "StartDate",
    endDate: "EndDate",
    baselineStartDate: "ActualStartDate",
    baselineEndDate: "ActualEndDate",
  };

  const toolbar = [
    "ExpandAll",
    "CollapseAll",
    "ZoomIn",
    "ZoomOut",
    "ZoomToFit",
    "ExcelExport",
    "CsvExport",
    "PdfExport",
  ];

  const timelineSettings: TimelineSettingsModel = {
    timelineUnitSize: 65,
    topTier: {
      unit: "None",
    },
    bottomTier: {
      unit: "Day",
      count: 1,
      format: "d",
    },
  };
  let dayWorkingTime = [{ from: 0, to: 24 }];

  const onClickToolbar = (args: ClickEventArgs) => {
    if (args.item.id?.endsWith("_excelexport")) {
      if (ganttRef.current) {
        ganttRef.current.excelExport();
      }
    } else if (args.item.id?.endsWith("_csvexport")) {
      if (ganttRef.current) {
        ganttRef.current!.csvExport();
      }
    } else if (args.item.id?.endsWith("_pdfexport")) {
      if (ganttRef.current) {
        ganttRef.current!.pdfExport({
          fitToWidthSettings: { isFitToWidth: true },
        });
      }
    }
  };

  if (loading) {
    return (
      <div className="d-grid-center bg-white p-4 border h-full">Loading...</div>
    );
  }

  if (
    !dayjs(currentProject["schedule_start_date"], "YYYY-MM-DD").isValid() ||
    !dayjs(currentProject["schedule_end_date"], "YYYY-MM-DD").isValid()
  ) {
    return (
      <div className="bg-white p-4 border h-full">
        Please select&nbsp;
        <span className="text-info heading">Schedule Start Date</span> and&nbsp;
        <span className="text-info heading">Schedule End Date</span>
      </div>
    );
  }

  return (
    <GanttComponent
      ref={ganttRef}
      dataSource={tasks}
      includeWeekend={true}
      highlightWeekends={true}
      renderBaseline={true}
      showColumnMenu={false}
      allowUnscheduledTasks={true}
      dateFormat="y-MM-dd"
      treeColumnIndex={1}
      allowSelection={true}
      toolbar={toolbar}
      projectStartDate={dayjs(
        currentProject["schedule_start_date"],
        "YYYY-MM-DD"
      )
        .subtract(1, "day")
        .format("YYYY-MM-DD")}
      projectEndDate={dayjs(currentProject["schedule_end_date"], "YYYY-MM-DD")
        .add(1, "day")
        .format("YYYY-MM-DD")}
      taskFields={taskFields}
      gridLines="Both"
      splitterSettings={{ position: "50%" }}
      timelineSettings={timelineSettings}
      height="100%"
      dayWorkingTime={dayWorkingTime}
      toolbarClick={onClickToolbar}
      allowExcelExport={true}
      allowPdfExport={true}
    >
      <ColumnsDirective>
        <ColumnDirective
          field="TaskName"
          headerText="Task Name"
          width="250"
          clipMode="EllipsisWithTooltip"
        ></ColumnDirective>
        <ColumnDirective
          field="StartDate"
          headerText="Planned Start Date"
          textAlign="Right"
        ></ColumnDirective>
        <ColumnDirective
          field="EndDate"
          headerText="Planned End Date"
          textAlign="Right"
        ></ColumnDirective>
        <ColumnDirective
          field="ActualStartDate"
          headerText="Actual Start Date"
          format={{ type: "date", format: "y-MM-dd" }}
          textAlign="Right"
        ></ColumnDirective>
        <ColumnDirective
          field="ActualEndDate"
          headerText="Actual End Date"
          format={{ type: "date", format: "y-MM-dd" }}
          textAlign="Right"
        ></ColumnDirective>
      </ColumnsDirective>
      <Inject
        services={[Selection, Toolbar, DayMarkers, ExcelExport, PdfExport]}
      />
    </GanttComponent>
  );
};

export { BaselineTab };
