import { useContext } from "react";
import { Notification, ProfileButton } from "../../components/header";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../lib/context";
import { IconButton } from "../../components/common";
import { IconHelp } from "@tabler/icons-react";

const Header = () => {
  const { profile } = useContext(GlobalContext);

  return (
    <header className="bg-white py-3 border-bottom">
      <div className="d-flex justify-between items-center h-10 container">
        <Link to="/">
          <img src="/images/logo.png" alt="" style={{ height: 40 }} />
        </Link>

        <div className="d-flex items-center">
          {profile["user_role"] === "Admin" && (
            <div
              onClick={() => location.href = "/cxspsa/dashboard"}
              className="mx-4 text-nowrap text-primary cursor-pointer"
            >
              Admin Workbench
            </div>
          )}

          <Notification />

          <IconButton>
            <IconHelp color="#666" size={18} />
          </IconButton>

          <ProfileButton />
        </div>
      </div>
    </header>
  );
};

export default Header;
