import { useLayoutEffect } from "react";

export interface NavigateProps {
  to: string;
}

export const Navigate = ({ to = "/" }: NavigateProps) => {
  useLayoutEffect(() => {
    location.href = to;
  }, []);

  return <div></div>;
};
