export const MILESTONES: string[] = [
  "Planned",
  "In Process",
  "In Testing",
  "Deployment",
  "Complete",
];

export const SEVERITIES: string[] = [
  "Business Critical",
  "Nice to have",
  "Future Planning",
];
